<template>
	<div class="row">
		<div class="col-12">
			<h4>Purchase Tickets</h4>
			<div class="text-left text-secondary">
				<h4>£{{venueTicketPriceTotal(tickets)}} for {{tickets}} tickets {{event ? 'for ' + event.name : ''}}</h4>
				<div
					class="tickets-fee"
				>Including Booking fee
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";

	export default {
		name: "PayingTicketReservation",
		props: [
			'tickets'
		],
		methods:{
			formattedTablePrice(tablePrice) {
				return new Intl.NumberFormat('en-GB', {
					maximumFractionDigits: 2
				}).format(tablePrice / 100)
			},
		},
		computed:{
			...mapGetters([ 'venueTicketPriceTotal', 'venueTicketBookingFee', 'event'])
		}
	}
</script>