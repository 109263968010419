<template>
	<div class="row" v-if="bookingItems">
		<div class="col-lg-6 offset-lg-3">
			<paying-table-reservation
				v-if="bookingItems.table"
				:table="bookingItems.table"
			></paying-table-reservation>
			<paying-ticket-reservation
				v-if="bookingItems.tickets"
				:tickets="bookingItems.tickets"
			></paying-ticket-reservation>
			<hr class="light-purple" />
			<div class="row mb-3">
				<div class="col-4">
					<h6 class="text-secondary">Club</h6>
					<h6>{{venue.name}}</h6>
				</div>
				<div class="col-4">
					<h6 class="text-secondary">Time</h6>
					<h6>{{venueOpeningHours}}</h6>
				</div>
				<div class="col-4">
					<h6 class="text-secondary">Date</h6>
					<h6>{{selectedDateFormatted}}</h6>
				</div>
			</div>
			<stripe-payment
				:stripe_key="stripe_key"
				:booking_items="bookingObject"
				:paying="creating"
				@success="successfulPayment"
			></stripe-payment>
		</div>
	</div>
</template>

<script>
	import {mapGetters, mapState, mapActions} from 'vuex';
	import PayingTableReservation from "../components/PayingTableReservation";
	import moment from 'moment';
	import StripePayment from "../components/StripePayment";
	import PayingTicketReservation from "../components/PayingTicketReservation";
	export default {
		name: "Payment",
		components: {PayingTicketReservation, StripePayment, PayingTableReservation},
		data() {
			return {
				stripe_key: '',
				creating: false,
			}
		},
		beforeMount() {
			if (!this.bookingItems){
				this.$router.push('/');
			}
			this.stripe_key = this.getStripeKey;
		},

		methods: {
			...mapActions(['confirmBooking']),

			successfulPayment(booking){
				this.confirmBooking(booking)
					.then(() => {
						this.$router.push('/payment_completed')
					})
					.catch((e) => {
						console.error('WTF?', e)
					})
			}
		},

		computed: {
			...mapGetters(['bookingItems', 'venue', 'venueOpeningHours', 'getStripeKey']),
			...mapState(['selectedDate', 'phoneNumber', 'marketingPermissions']),

			selectedDateFormatted(){
				return moment(this.selectedDate).format('DD MMMM YYYY');
			},

			bookingObject(){
				let _object = {
					date: moment(this.selectedDate).format('DD-MM-YYYY'),
					venue_id: this.venue.id,
					tables: [],
					phone_number: this.phoneNumber,
					marketing_permissions: this.marketingPermissions
				};

				if (this.bookingItems.table) {
					_object.tables = [{id: this.bookingItems.table.id, notes: ''}];
				}
				if (this.bookingItems.tickets) {
					_object.tickets = this.bookingItems.tickets;
				}

				return _object;
			},
		}
	}
</script>