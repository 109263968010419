<template>
	<div class="row">
		<div class="col-12">
			<h4>Reserve {{table.name}} Table</h4>
			<div class="text-left text-secondary">
				<h4>£{{ formattedTablePrice(table.price + availability.table_booking_fee) }} for {{table.size}} people {{event ? 'for ' + event.name : ''}}</h4>
				<div
					class="tickets-fee"
				>Including Booking fee
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";

	export default {
		name: "PayingTableReservation",
		props: [
			'table'
		],
		methods:{
			formattedTablePrice(tablePrice) {
				return new Intl.NumberFormat('en-GB', {
					maximumFractionDigits: 2
				}).format(tablePrice / 100)
			},
		},
		computed:{
			...mapGetters({ availability: 'selectedDateAvailability', event: 'event' })
		}
	}
</script>