<template>
	<div class="row">
		<div class="col-12">
			<div ref="card"></div>

			<half-circle-spinner
				v-if="loading"
				:animation-duration="2000"
				:size="20"
				color="#fff"
				class="mx-auto mt-3"
			/>

			<button class="btn btn-primary mt-3 w-100" v-bind:disabled="paying" v-on:click="purchase">
				<span class="mr-3">
					Pay now
				</span>
			</button>
			<span class="text-danger mt-3" v-if="error_msg">{{error_msg}}</span>
		</div>
	</div> 
</template>

<script>
	import { HalfCircleSpinner } from 'epic-spinners'
	import {mapActions} from "vuex";

	export default {
		components: {
			HalfCircleSpinner,
		},

		name: "StripePayment",
		props: [
			'booking_items',
			'stripe_key',
			'paying'
		],

		data(){
			return {
				stripe: undefined,
				elements: undefined,
				card: undefined,
				loading: false,

				creating: false,
				error_msg: undefined,
				booking: undefined
			}
		},

		mounted: function () {
			let _this = this;

			_this.stripe = Stripe(_this.stripe_key);
			_this.elements = _this.stripe.elements();

			_this.card = _this.elements.create('card', {
				hidePostalCode: true,
				style: {
					base: {
						color: '#32325d',
						fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
						fontSmoothing: 'antialiased',
						fontSize: '16px',
						'::placeholder': {
							color: '#aab7c4'
						}
					},
					invalid: {
						color: '#fa755a',
						iconColor: '#fa755a'
					}
				}
			});

			_this.card.addEventListener('change', function() {
				_this.$emit('change')
			});
			_this.card.mount(_this.$refs.card);
		},

		methods: {
			...mapActions(['createBooking', 'payBooking']),

			purchase(){
				let _this = this;
				this.loading = true;

				if (_this.creating){
					return;
				}

				_this.creating = true;

				if (!_this.booking){
					_this.createBooking(_this.booking_items)
						.then(function (response) {
							_this.booking = response.data.success.data;
							console.log('Booking created: ', _this.booking);

							_this.pay();
						})
				}
				else {
					_this.pay();
				}
			},

			pay(){
				this.stripe.confirmCardPayment(this.booking.client_secret, {
					payment_method: {
						card: this.card,
					}
				})
					.then((result) => {
						if (result.error) {
							this.error_msg = result.error.message;
							this.creating = false;
							this.loading = false;
						}
						else {
							if (result.paymentIntent.status === 'succeeded') {
								delete this.booking.client_secret;
								this.$emit('success', this.booking);
							}
							else {
								new Error('Something went wrong?: '. JSON.stringify(result));
							}
						}
					});
			}
		}
	}
</script>

<style>
	/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
	.StripeElement {
		box-sizing: border-box;

		height: 40px;

		padding: 10px 12px;

		border: 1px solid transparent;
		border-radius: 4px;
		background-color: white;

		box-shadow: 0 1px 3px 0 #e6ebf1;
		-webkit-transition: box-shadow 150ms ease;
		transition: box-shadow 150ms ease;
	}

	.StripeElement--focus {
		box-shadow: 0 1px 3px 0 #cfd7df;
	}

	.StripeElement--invalid {
		border-color: #fa755a;
	}

	.StripeElement--webkit-autofill {
		background-color: #fefde5 !important;
	}
</style>